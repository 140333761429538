<template>
  <el-tabs v-show="currentOverlay"
    v-model="currentOverlay"
    type="card"
    @change="updateCurrentOverlay"
    @tab-click="$router.push(`/company/markets/market/${currentMarket.id}/${currentOverlay}`, () => {})">
    <!-- INVOICES -->
    <el-tab-pane v-if="supply"
      v-loading="downloadingFile"
      :element-loading-text="$t('general.preparing-download')"
      :label="$t('billing-markets.invoices')"
      name="invoice"
      class="billing-market-panel">
      <div class="grid billing-market-next-invoice m-2">
        <div>{{ $t('billing-markets.next-invoice') }}<br><span class="fs-1-2rem">{{ $t('billing-markets.disclaimer') }}</span></div>
        <div v-loading="loadingEstimate"
          class="font-weight-500 text-align-right"
          data-name="next-invoice"
          data-testid="next-invoice">
          {{ estimatedNextInvoice }}
        </div>
        <div class="billing-market-next-invoice-hr">
          <hr>
        </div>
        <div>
          {{ $t('billing-markets.balance') }}<br><span class="fs-1-2rem">{{ $t('billing-markets.inc-tax') }}</span>
        </div>
        <div class="font-weight-500 text-align-right"
          data-name="account-balance"
          data-testid="account-balance">
          {{ supply.accountBalance | formatInCurrency(supply.currencyEnum) }}
        </div>
        <div>
          <el-button target="_blank"
            class="el-button el-button--primary mt-1 downloadLink"
            @click="download(statementUrl)">
            {{ $t('billing-markets.download-statement') }}
          </el-button>
        </div>
      </div>

      <hr class="mb-2">

      <div class="flex-row-centered mx-1 mb-2">
        <p class="date-range mr-2">
          {{ $t('billing-markets.date-range') }}
        </p>
        <el-date-picker v-model="invoiceDateRange"
          :picker-options="pickerOptions"
          format="dd-MM-yyyy"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          @change="syncInvoices" />
      </div>

      <el-table v-loading="loadingInvoices"
        :data="currentSupplyInvoices"
        :empty-text="$t('billing-markets.no-bills')"
        stripe
        max-height="460"
        class="normal-wrap">
        <!-- DATE -->
        <el-table-column :label="$t('billing-markets.invoice-date')">
          <template #default="{row}">
            <div>{{ row.issueDate }}</div>
          </template>
        </el-table-column>
        <!-- NUMBER -->
        <el-table-column property="invoiceId"
          :label="$t('billing-markets.invoice-no')" />
        <!-- DUE DATE -->
        <el-table-column :label="$t('billing-markets.due-date')"
          width="100">
          <template #default="{row}">
            <div>{{ dueDate(row) }}</div>
          </template>
        </el-table-column>
        <!-- AMOUNT -->
        <el-table-column :label="$t('billing-markets.invoice-amount')"
          align="right">
          <template #default="{row}">
            <span>{{ row.invoiceAmount | formatInCurrency(supply.currencyEnum) }}</span>
          </template>
        </el-table-column>
        <!-- OPEN BALANCE -->
        <el-table-column :label="$t('billing-markets.open-balance')"
          align="right">
          <template #default="{row}">
            <span>{{ row.openBalance | formatInCurrency(supply.currencyEnum) }}</span>
          </template>
        </el-table-column>
        <!-- DOWNLOAD -->
        <el-table-column :label="$t('billing-markets.download')"
          align="right">
          <template #default="{row}">
            <el-button type="text"
              @click="download(row.csv)">
              <mu-mega-icon class="download-icon mr-2"
                icon="Csv" />
              <span class="sr-only">{{ $t('billing-markets.download-csv') }}</span>
            </el-button>
            <el-button type="text"
              @click="download(row.pdf)">
              <mu-mega-icon class="download-icon"
                icon="Pdf" />
              <span class="sr-only">{{ $t('billing-markets.download-pdf') }}</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>

    <!-- PAYMENTS -->
    <el-tab-pane v-if="supply && !isDistributorAccount"
      :label="$t('billing-markets.payments')"
      name="payments"
      class="billing-market-panel">
      <div v-if="canAcceptStripePayment"
        data-name="payment-accepted"
        data-testid="payment-accepted">
        <stripe v-if="hasStripe"
          :market-id="currentMarket.id"
          @refreshPayments="updateCardPaymentStore" />
        <div v-else>
          {{ $t('billing-markets.no-stripe') }}
        </div>

        <template v-if="cardPayments.length > 0">
          <div class="text-align-center mt-1">
            <hr class="mb-2">
            <h4 class="mb-2">
              {{ $t('billing-markets.cc-history') }}
            </h4>
            <el-table :data="cardPayments"
              :empty-text="$t('billing-markets.no-payments')"
              stripe
              max-height="450"
              class="normal-wrap">
              <el-table-column property="description"
                :label="$t('billing-markets.description')" />
              <el-table-column property="datePaid"
                :label="$t('billing-markets.payment-date')" />
              <el-table-column align="right"
                :label="$t('billing-markets.total')">
                <template #default="{row}">
                  <span data-name="paymentTotal">{{ row.amount | formatInCurrency(supply.currencyEnum) }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </div>
      <template v-else-if="!supply.stripeAccountPublishableKey">
        <p data-name="payment-denied-market"
          data-testid="payment-denied-market">
          {{ $t('billing-markets.unavailable-market') }}
        </p>
      </template>
      <template v-else>
        <p data-name="payment-denied-currency"
          data-testid="payment-denied-currency">
          {{ $t('billing-markets.dont-accept-currency', { currency: supply.currencyEnum }) }}
        </p>
      </template>
    </el-tab-pane>

    <!-- EDIT -->
    <el-tab-pane :label="canEditBillingMarkets ? $t('billing-markets.edit') : $t('billing-markets.view')"
      name="edit"
      class="billing-market-panel">
      <div v-loading="savingBillingMarket"
        :element-loading-text="$t('general.saving')">
        <el-form ref="marketForm"
          :model="marketForm"
          :rules="marketRules"
          label-width="335px"
          class="show-non-editable"
          :disabled="!canEditBillingMarkets">
          <!-- Can't edit it if it's already been saved -->
          <el-form-item prop="currencyEnum"
            :label="$t('billing-markets.billing-currency')">
            <el-radio-group v-model="marketForm.currencyEnum"
              :disabled="wellKnownSupplier"
              data-name="billing-currency"
              data-testid="billing-currency">
              <el-radio-button v-for="currency in currentMarket.availableCurrencies"
                :key="currency"
                :label="currency" />
            </el-radio-group>
            <div v-if="wellKnownSupplier">
              {{ $t('billing-markets.currency-fixed') }}
            </div>
            <div v-else>
              {{ $t('billing-markets.no-currency-change') }}
            </div>
          </el-form-item>

          <el-form-item v-if="currentMarket.availableInvoiceLanguages && currentMarket.availableInvoiceLanguages.length > 1"
            prop="language">
            <template #label>
              {{ $t('billing-markets.language') }}
              <el-tooltip placement="top"
                :content="$t('billing-markets.language-future-invoices')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>

            <el-radio-group v-model="marketForm.language"
              data-name="invoice-language"
              data-testid="invoice-language">
              <el-radio-button v-for="language in currentMarket.availableInvoiceLanguages"
                :key="language"
                :label="language">
                {{ language.toUpperCase() }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item prop="companyLegalName">
            <template #label>
              {{ $t('company.company-legal-name') }}
              <el-popover v-if="supply"
                placement="top"
                width="400"
                popper-class="custom-popover-background"
                trigger="hover">
                <p class="tooltip-p m-0 word-break-normal">
                  {{ $t('billing-markets.change-legal-name-info') }}
                  <router-link v-if="megaportContactsAvailable"
                    to="/company/support"
                    target="_blank">
                    <span>
                      {{ $t('billing-markets.view-megaport-contacts') }}
                      <i class="fas fa-arrow-up-right-from-square"
                        aria-hidden="true" />
                    </span>
                  </router-link>
                </p>
                <span slot="reference">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </span>
              </el-popover>
            </template>
            <el-input v-model="marketForm.companyLegalName"
              name="companyLegalName"
              data-demo="Example Company"
              data-testid="company-legal-name-input"
              :disabled="supply ? true : false" />
          </el-form-item>

          <el-form-item prop="billingContactName"
            :label="$t('billing-markets.billing-name')">
            <el-input v-model="marketForm.billingContactName"
              name="billingContactName"
              data-demo="Barney Rubble"
              data-testid="contact-name-input" />
          </el-form-item>

          <el-form-item prop="billingContactPhone">
            <template #label>
              {{ $t('billing-markets.billing-phone') }}
              <el-tooltip placement="top"
                :content="$t('billing-markets.phone-format')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model="marketForm.billingContactPhone"
              placeholder="+61 7 40309999"
              name="phone"
              data-demo="+61 7 12341234"
              data-testid="contact-phone-input" />
          </el-form-item>

          <el-form-item prop="billingContactEmail">
            <template #label>
              {{ $t('billing-markets.billing-email') }}
              <el-tooltip placement="top"
                :content="$t('billing-markets.email-format')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model.trim="marketForm.billingContactEmail"
              name="billingContactEmail"
              type="email"
              autocomplete="email"
              data-demo="barney@bedrock.org"
              data-testid="contact-email-input" />
          </el-form-item>

          <el-form-item prop="address1">
            <template #label>
              {{ $t('billing-markets.billing-address') }}
              <el-tooltip v-if="canEditBillingMarkets"
                placement="top"
                :content="$t('billing-markets.billing-address-entry-rules')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model="marketForm.address1"
              name="address1"
              data-demo="53 Stone ln"
              data-testid="address-one-input" />
          </el-form-item>

          <el-form-item prop="address2"
            :label="$t('billing-markets.address-2')">
            <el-input v-model="marketForm.address2"
              name="address2"
              data-testid="address-two-input" />
          </el-form-item>

          <el-form-item prop="city"
            :label="$t('billing-markets.city')"
            data-testid="city">
            <el-input v-model="marketForm.city"
              name="city"
              data-demo="Bedrock"
              data-testid="city-input" />
          </el-form-item>

          <el-form-item prop="state"
            :label="marketForm.country === 'CA' ? $t('billing-markets.province') : $t('billing-markets.state')"
            data-testid="state">
            <template v-if="marketForm.country === 'CA'">
              <el-select v-model="marketForm.state"
                :placeholder="$t('general.please-select')"
                class="width-225px">
                <el-option value="ON"
                  :label="$t('canada.on')" />
                <el-option value="QC"
                  :label="$t('canada.qc')" />
                <el-option value="NS"
                  :label="$t('canada.ns')" />
                <el-option value="NB"
                  :label="$t('canada.nb')" />
                <el-option value="MB"
                  :label="$t('canada.mb')" />
                <el-option value="BC"
                  :label="$t('canada.bc')" />
                <el-option value="PE"
                  :label="$t('canada.pe')" />
                <el-option value="SK"
                  :label="$t('canada.sk')" />
                <el-option value="AB"
                  :label="$t('canada.ab')" />
                <el-option value="NL"
                  :label="$t('canada.nl')" />
              </el-select>
            </template>
            <template v-else>
              <el-input v-model="marketForm.state"
                name="state"
                data-demo="FLINT"
                data-testid="state-input" />
            </template>
          </el-form-item>

          <el-form-item prop="postcode"
            :label="$t('billing-markets.postcode')">
            <el-input v-model="marketForm.postcode"
              name="postcode"
              data-demo="12345"
              data-testid="postcode-input" />
          </el-form-item>

          <el-form-item prop="country"
            data-testid="country">
            <template #label>
              <span class="capitalize-text">{{ $t('connections.country') }}</span>
              <el-tooltip placement="top"
                :content="$t('billing-markets.select-or-type')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-select v-model="marketForm.country"
              :placeholder="$t('general.please-select')"
              data-name="market-country"
              data-testid="country-select"
              filterable
              autocomplete="remove-auto-complete"
              class="width-225px"
              @change="checkMarket">
              <el-option v-for="country in countryList"
                :key="country.code"
                :data-market-country="country.name"
                :value="country.code"
                :label="country.name" />
            </el-select>
          </el-form-item>

          <hr>

          <el-form-item prop="companyLegalIdentifier">
            <template #label>
              {{ $t('billing-markets.company-registration') }}
              <el-popover placement="top"
                width="400"
                popper-class="custom-popover-background"
                trigger="hover">
                <p v-if="supply"
                  class="tooltip-p m-0 word-break-normal">
                  {{ $t('billing-markets.change-registration-number-info') }}
                  <router-link v-if="megaportContactsAvailable"
                    to="/company/support"
                    target="_blank">
                    <span>
                      {{ $t('billing-markets.view-megaport-contacts') }}
                      <i class="fas fa-arrow-up-right-from-square"
                        aria-hidden="true" />
                    </span>
                  </router-link>
                </p>
                <p v-else
                  class="tooltip-p m-0 word-break-normal">
                  {{ $t('billing-markets.company-tooltip') }}
                </p>
                <span slot="reference">
                  <i class="el-icon-info color-info"
                    aria-hidden="true" />
                </span>
              </el-popover>
            </template>
            <el-input v-model="marketForm.companyLegalIdentifier"
              name="companyLegalIdentifier"
              data-demo="ABN123456"
              data-testid="company-legal-identifier-input" 
              :disabled="supply ? true : false" />
          </el-form-item>

          <!-- FCC FUSF - US Market only-->
          <el-form-item v-if="fccFieldVisible"
            prop="fusfInterstateTrafficCertification">
            <template #label>
              {{ $t('billing-markets.fusf-interstate-traffic-certification') }}
              <el-tooltip placement="top"
                :open-delay="500">
                <template #content>
                  <p class="tooltip-p"
                    v-html="$t('billing-markets.fusf-tooltip-content')" /> <!-- eslint-disable-line vue/no-v-html -->
                </template>
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-checkbox v-model="usMarketFields.fusfInterstateTrafficCertification"
              name="fusf-certification"
              data-name="fusf-certification"
              data-testid="fusf-certification" />
            <div>{{ $t('billing-markets.fusf-recommended-text') }}</div>
          </el-form-item>

          <div v-if="mxMarket">
            <el-form-item prop="companyTypeMexico">
              <template #label>
                <span class="capitalize-text">
                  {{ $t('billing-markets.company-type-mexico') }}
                </span>
                <el-tooltip placement="top"
                  :content="$t('billing-markets.company-type-mexico-tooltip')"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <el-select v-model="mxMarketFields.companyTypeMexico"
                :placeholder="$t('general.please-select')"
                class="width-225px"
                data-testid="company-type-mexico"
                @change="onCompanyTypeChange()">
                <el-option v-for="option in companyTypeMexicoOptions"
                  :key="option.id"
                  :value="option.id"
                  :label="$t(getInternationalizationString(['mexico-billing-market', 'company-type-mexico'], option.id))" />
              </el-select>
            </el-form-item>

            <el-form-item prop="taxRegimeCodeMexico">
              <template #label>
                <span class="capitalize-text">
                  {{ $t('billing-markets.tax-regime-code-mexico') }}
                </span>
                <el-tooltip placement="top"
                  :content="$t('billing-markets.tax-regime-code-mexico-tooltip')"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <el-select v-model="mxMarketFields.taxRegimeCodeMexico"
                :placeholder="$t('general.please-select')"
                class="width-225px"
                data-testid="tax-regime-code-mexico"
                @change="onTaxRegimeCodeMexicoChange()">
                <el-option v-for="option in taxRegimeCodeMexicoOptions"
                  :key="option"
                  :value="option"
                  :label="$t(`mexico-billing-market.tax-regime-code-mexico-${option}`)" />
              </el-select>
            </el-form-item>

            <el-form-item prop="satPurposeMexico">
              <template #label>
                <span class="capitalize-text">
                  {{ $t('billing-markets.sat-purpose-mexico') }}
                </span>
                <el-tooltip placement="top"
                  :content="$t('billing-markets.sat-purpose-mexico-tooltip')"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <el-select v-model="mxMarketFields.satPurposeMexico"
                :placeholder="$t('general.please-select')"
                data-testid="sat-purpose-mexico"
                class="width-225px">
                <el-option v-for="option in satPurposeMexicoOptions"
                  :key="option"
                  :value="option"
                  :label="$t(`mexico-billing-market.sat-purpose-mexico-${option.toLowerCase()}`)" />
              </el-select>
            </el-form-item>

            <el-form-item ref="rfcMexico"
              class="long-error"
              prop="rfcMexico">
              <template #label>
                <span class="capitalize-text">
                  {{ $t('billing-markets.rfc-mexico') }}
                </span>
                <el-tooltip placement="top"
                  :content="$t('billing-markets.rfc-mexico-tooltip')"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <el-input v-model="mxMarketFields.rfcMexico"
                :disabled="disableRfcMexico"
                name="rfcMexico"
                data-demo="ABCD123456XYZ"
                data-testid="rfc-mexico"
                @input.native="mxMarketFields.rfcMexico = $event.target.value.toUpperCase()" />
            </el-form-item>
          </div>

          <div v-if="euMarket && euBillingMarket">
            <el-form-item prop="yourVATNumber"
              data-name="yourVATNumber"
              :label="$t('billing-markets.vat-registration')">
              <el-radio-group v-model="vatRegistered"
                data-testid="vat-radio"
                @change="vatRegisteredHandler">
                <el-radio-button :label="true">
                  {{ $t('general.yes') }}
                </el-radio-button>
                <el-radio-button :label="false">
                  {{ $t('general.no') }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="vatRegistered"
              prop="taxNumber">
              <template #label>
                {{ $t('billing-markets.vat-id') }}
                <el-tooltip placement="top"
                  :content="$t('billing-markets.vat-number')"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <el-input v-model="marketForm.taxNumber"
                name="taxNumber">
                <template #prepend>
                  {{ marketForm.country }}
                </template>
              </el-input>
            </el-form-item>
          </div>

          <div v-if="satisfiesFobAccReq && satisfiesFobBillReq">
            <el-form-item data-name="uk-mobq">
              <template #label>
                {{ $t(`billing-markets.${financeOnboardingQuestion}`) }}
                <el-tooltip placement="top"
                  :content="$t(`billing-markets.${financeOnboardingQuestionTooltip}`)"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <el-radio-group v-model="mobqRadio"
                data-testid="mobq-radio">
                <el-radio-button :label="true">
                  {{ $t('general.yes') }}
                </el-radio-button>
                <el-radio-button :label="false">
                  {{ $t('general.no') }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>

          <el-form-item prop="yourPoNumber">
            <template #label>
              {{ $t('billing-markets.po') }}
              <el-tooltip placement="top"
                :content="$t('billing-markets.purchase-order-market', { country: currentMarket.country })"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model="marketForm.yourPoNumber"
              name="yourPoNumber"
              data-demo="1234568"
              data-testid="po-number-input"
              autocomplete="off" />
          </el-form-item>

          <el-form-item id="agreement"
            prop="agreement"
            style="vertical-align:top">
            <div class="notices">
              <strong>{{ $t('general.important-information') }}</strong>
              <br>
              <el-checkbox v-model="marketForm.agreement"
                name="company-agreement"
                data-name="company-agreement"
                data-testid="company-agreement"
                :disabled="supply ? true : false">
                <span style="white-space:normal"
                  v-html="agreementHtml" /> <!-- eslint-disable-line vue/no-v-html -->
              </el-checkbox>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import CountryMixin from '@/mixins/country.js'
import StripeComponent from '@/components/payments/Stripe.vue'
import { moneyFilter, mpDate } from '@/helpers.js'
import { PHONE_NUMBER_REGEX } from '@/validators'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { DateTime } from 'luxon'
import { mapActions, mapState, mapGetters } from 'vuex'
import { COMPANY_SETUP_LEGALESE, ACCOUNT_TYPE_DIRECT_CUSTOMER } from '@/Globals.js'
import sdk from '@megaport/api-sdk'
import { kebabCase } from 'lodash'
import { downloadFile } from '@/utils/downloadFile'

export default {
  name: 'CompanyMarketTabs',

  components: {
    stripe: StripeComponent,
  },

  filters: {
    formatInCurrency: moneyFilter,
  },

  mixins: [CountryMixin],

  props: {
    value: {
      type: String,
      default: null,
    },
    currentMarket: {
      type: Object,
      default: null,
    },
    canEditBillingMarkets: Boolean,
  },

  data() {
    return {
      cardPaymentsStore: [],
      currentOverlay: this.value,
      vatRegistered: false,
      euMarket: false,
      euBillingMarket: false,
      mobqRadio: true,
      savingBillingMarket: false,
      invoices: null,
      loadingInvoices: false,
      loadingEstimate: true,
      downloadingFile: false,
      balanceData: null,
      invoiceDateRange: [
        DateTime.now().minus({ years: 1 }).toISO(),
        DateTime.now().toISO(),
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      marketForm: {
        currencyEnum: null,
        language: null,
        companyLegalIdentifier: null,
        companyLegalName: null,
        billingContactName: null,
        billingContactPhone: null,
        billingContactEmail: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        postcode: null,
        country: null,
        yourPoNumber: null,
        taxNumber: null,
        agreement: false,
      },
      mxMarketFields: {
        companyTypeMexico: null,
        taxRegimeCodeMexico: null,
        satPurposeMexico: null,
        rfcMexico: null,
      },
      usMarketFields: {
        fusfInterstateTrafficCertification: false,
      },
      marketRules: {
        currencyEnum: { required: true, message: this.$t('validations.please-select', { thing: this.$t('billing-markets.currency') }), trigger: 'blur' },
        language: { required: true, message: this.$t('validations.please-select', { thing: this.$t('billing-markets.language') }), trigger: 'blur' },
        companyLegalIdentifier: { required: true, message: this.$t('validations.please-enter', { thing: this.$t('billing-markets.company-identifier') }), trigger: 'blur' },
        companyLegalName: { required: true, message: this.$t('validations.please-enter', { thing: this.$t('company.company-legal-name') }), trigger: 'blur' },
        billingContactName: { required: true, message: this.$t('validations.please-enter', { thing: this.$t('billing-markets.contact-name') }), trigger: 'blur' },
        billingContactPhone: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('billing-markets.phone-number') }), trigger: 'blur' },
          { pattern: PHONE_NUMBER_REGEX, message: this.$t('validations.phone-format'), trigger: 'blur' },
        ],
        billingContactEmail: { required: true, validator: this.validateEmailList, trigger: 'blur' },
        address1: { required: true, message: this.$t('validations.required', { thing: this.$t('billing-markets.address') }), trigger: 'blur' },
        city: { required: true, message: this.$t('validations.required', { thing: this.$t('billing-markets.city') }), trigger: 'blur' },
        state: { required: true, message: this.$t('validations.required', { thing: this.$t('billing-markets.state') }), trigger: 'blur' },
        postcode: { required: true, message: this.$t('validations.required', { thing: this.$t('billing-markets.postcode') }), trigger: 'blur' },
        country: { required: true, message: this.$t('validations.required', { thing: this.$t('connections.country') }), trigger: 'blur' },
        yourPoNumber: { max: 54, message: this.$tc('validations.max-length', 54, { max: 54 }), trigger: 'blur' },
        taxNumber: [
          {
            trigger: 'blur',
            validator: this.validateTaxNumber,
          },
        ],
        agreement: { required: true, validator: this.validateBooleanTrue, trigger: 'blur' },
        companyTypeMexico: { required: true, trigger: 'blur', validator: this.validateCompanyTypeMexico },
        taxRegimeCodeMexico: { required: true, trigger: 'blur', validator: this.validateTaxRegimeCodeMexico },
        satPurposeMexico: { required: true, trigger: 'blur', validator: this.validateSatPurposeMexico },
        rfcMexico: { required: true, trigger: 'blur', validator: this.validateRfcMexico },
      },
      showMarketStrategyMap: {
        MX: this.mexicoShowMarketStrategy,
      },
      mexicoData: null,
    }
  },

  computed: {
    ...mapGetters('ApplicationContext', ['isManagedContext']),
    ...mapGetters('Auth', ['isDistributorAccount', 'accountType', 'isPartnerAccount', 'isManagedAccount', 'isDirectAccount', 'isPartnerSupported']),
    ...mapGetters('Company', ['companyId', 'companyUid', 'megaportContactDetails']),
    ...mapState('Auth', { authData: state => state.data }),
    statementUrl() {
      return `${sdk.instance.baseurl}/v2/company/${this.companyId}/statement/supplierId/${this.currentMarket.id}`
    },
    dueDate() {
      return invoice => {
        return invoice.dueDate
          ? DateTime.fromMillis(Date.parse(invoice.dueDate)).toFormat('dd/LL/y')
          : this.$t('billing-markets.due-unavailable')
      }
    },
    wellKnownSupplier() {
      if (this.currentMarket && this.supply && this.supply.wellKnownSupplier) {
        return true
      }
      return false
    },
    currentSupplyInvoices() {
      if (!this.invoices) {
        this.syncInvoices()
        return null
      }

      return this.invoices
    },
    cardPayments() {
      return this.cardPaymentsStore.length ? this.cardPaymentsStore.map(c => {
        c.datePaid = mpDate(c.created)
        return c
      })
        .sort((a, b) => {
          return b.created - a.created
        }) : []
    },
    hasStripe() {
      return window.Stripe !== undefined
    },
    canAcceptStripePayment() {
      return (
        this.supply.stripeSupportedBankCurrencies.includes(this.supply.currencyEnum) &&
        this.supply.stripeAccountPublishableKey
      )
    },
    supply() {
      // making the supply object reactive due to prop currentMarket being updated asynchronously
      return this.currentMarket.supply
    },
    specialMarketRegionCode() {
      // Markets with special finance onboarding questions
      const specialMarketCodes = ['UK', 'AU', 'SG']
      // Check if the current market has special finance considerations
      const currentMarketCode = this.currentMarket.market
      return specialMarketCodes.includes(currentMarketCode) ? currentMarketCode : null
    },
    satisfiesFobAccReq() {
      return this.accountType === ACCOUNT_TYPE_DIRECT_CUSTOMER &&
        this.canEditBillingMarkets
    },
    satisfiesFobBillReq() {
      let show = false
      if (this.specialMarketRegionCode && this.marketForm.country) {
        // For UK market and a European billing address NOT in the UK
        if (this.specialMarketRegionCode === 'UK' && this.euBillingMarket && this.marketForm.country !== 'UK') {
          // Finance onboarding questions displaying depends on the VAT registration
          show = this.vatRegistered
        } else
        // For the other special markets, finance onboarding questions only display when the billing address is an overseas address
          show = this.marketForm.country !== this.specialMarketRegionCode
      }
      return show
    },
    financeOnboardingQuestion() {
      const currentSpecialMarketCode = this.specialMarketRegionCode
      return currentSpecialMarketCode ? `${currentSpecialMarketCode}_MOBQ` : null
    },
    financeOnboardingQuestionTooltip() {
      const currentSpecialMarketCode = this.specialMarketRegionCode
      return currentSpecialMarketCode ? `${currentSpecialMarketCode}_MOBQ_TOOLTIP` : null
    },
    financeOnboarding() {
      // Payload to be added to the market
      let payload = {
        // Register the question type to match API values
        question: this.specialMarketRegionCode === 'UK' ? 'PERSONNEL_Q' : 'GST_REGISTERED_Q',
        // Register the answer
        response: this.mobqRadio ? 'YES' : 'NO',
      }
      return this.satisfiesFobAccReq && this.satisfiesFobBillReq ? payload : {}
    },
    agreementHtml() {
      return COMPANY_SETUP_LEGALESE
    },
    estimatedNextInvoice() {
      return moneyFilter(this.balanceData?.estimate, this.supply.currencyEnum)
    },
    mxMarket() {
      return this.currentMarket.market === 'MX'
    },
    fccFieldVisible() {
      return this.currentMarket.market === 'US' && !this.isDistributorAccount
    },
    companyTypeMexicoOptions() {
      return this.mexicoData?.taxFieldRelations ?? []
    },
    selectedCompanyType() {
      return this.mexicoData?.taxFieldRelations?.find(item => item.id === this.mxMarketFields.companyTypeMexico)
    },
    taxRegimeCodeMexicoOptions() {
      const taxList = this.selectedCompanyType?.taxRegimeList ?? []
      return taxList.map(item => item.id)
    },
    selectedRegime() {
      const taxRegimeCodeMexico = this.mxMarketFields.taxRegimeCodeMexico
      return taxRegimeCodeMexico ? this.selectedCompanyType.taxRegimeList.find(item => item.id === taxRegimeCodeMexico) : null
    },
    satPurposeMexicoOptions() {
      return this.selectedRegime?.satPurposeList ?? []
    },
    rfcMexicoPattern() {
      return this.selectedRegime?.rfcSpecification?.pattern ?? null
    },
    disableRfcMexico() {
      return this.selectedRegime?.rfcSpecification?.pattern ? false : true
    },
    rfcExample() {
      switch (this.rfcMexicoPattern) {
        case '^[A-Z0-9]{3}[0-9]{6}[A-Z0-9]{3}$':
          return this.$t('validations.rfc-number-example', { total: 12, start: 3 })
        case '^[A-Z0-9]{4}[0-9]{6}[A-Z0-9]{3}$':
          return this.$t('validations.rfc-number-example', { total: 13, start: 4 })
        default:
          return ''
      }
    },
    accountContactsMenuVisible() {
      return (((this.isPartnerAccount || this.isPartnerSupported || this.isManagedAccount) && !this.isManagedContext) || this.isDirectAccount)
    },
    megaportContactsAvailable() {
      if (!this.accountContactsMenuVisible) return false
      return this.megaportContactDetails?.accountManager?.active || this.megaportContactDetails?.customerSuccessManager?.active
    },
  },

  watch: {
    value(val) {
      this.currentOverlay = val
    },
    currentMarket: {
      immediate: true,
      handler(val) {
        this.updateRequiredFields()

        if (val && val.id) {
          this.updateCardPaymentStore()
          this.syncInvoices()
          this.loadBalances()
        }
        if (val.market === 'MX') {
          this.mexicoShowMarketStrategy()
        }
        if (val.market === 'US') {
          this.usMarketFields.fusfInterstateTrafficCertification = this.supply?.countrySpecificTaxFields?.fusfInterstateTrafficCertification || false
        }
      },
    },
    selectedRegime(regime) {
      this.updateRFCSpecification(regime?.rfcSpecification)
    },
  },

  methods: {
    ...mapActions('Markets', ['postMarketSupply']),
    async download(url) {
      this.downloadingFile = true
      try {
        await downloadFile(url)
      } finally {
        this.downloadingFile = false
      }
    },
    /**
     * Validate email
     * @param {Object} _rule
     * @param {string} value
     * @param {function} callback
     */
    validateEmailList(_rule, value, callback) {
      if (!value) {
        callback(this.$t('validations.required', { thing: this.$t('general.email') }))
      } else {
        // From https://github.com/yiminghe/async-validator/blob/master/src/rule/type.js
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const emails = value.split(/[,]+/).map(e => e.trim())

        for (const email of emails) {
          if (!emailRegex.test(email)) {
            const invalidSeperators = emails.join().match(/[:; ]/g)

            if (invalidSeperators) {
              callback(this.$t('validations.email-invalid-seperators'))
              return
            } else {
              callback(this.$t('users.invalid-email', { email: email }))
              return
            }
          }
        }
        callback()
      }
    },
    /**
     * Validate tax number
     * @param {Object} _rule
     * @param {string} taxNumber
     * @param {Funciton} callback
     */
    validateTaxNumber(_rule, taxNumber, callback) {
      if (taxNumber === null || taxNumber === '') {
        callback(new Error(this.$t('validations.required', { thing: this.$t('billing-markets.vat') })))
      } else if (this.countryRules.len && taxNumber.length !== this.countryRules.len) {
        callback(new Error(this.countryRules.message))
      } else if (
        this.countryRules.min &&
        this.countryRules.max &&
        (taxNumber.length < this.countryRules.min || taxNumber.length > this.countryRules.max)
      ) {
        callback(new Error(this.countryRules.message))
      } else {
        callback()
      }
    },
    /**
     * Validate agreement checkbox is checked
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateBooleanTrue(_rule, value, callback) {
      if (typeof value !== 'boolean' || !value) {
        callback(new Error(this.$t('validations.agree-terms')))
      } else {
        callback()
      }
    },
    /**
     * Validate Company Type
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateCompanyTypeMexico(_rule, value, callback) {
      if (!this.mxMarketFields.companyTypeMexico) {
        callback(new Error(this.$t('validations.required', { thing: this.$t('billing-markets.company-type-mexico') })))
      } else {
        callback()
      }
    },
    /**
     * Validate Tax Regime
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateTaxRegimeCodeMexico(_rule, value, callback) {
      if (!this.mxMarketFields.taxRegimeCodeMexico) {
        callback(new Error(this.$t('validations.required', { thing: this.$t('billing-markets.tax-regime-code-mexico') })))
      } else {
        callback()
      }
    },
    /**
     * Validate Tax Purpose
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateSatPurposeMexico(_rule, value, callback) {
      if (!this.mxMarketFields.satPurposeMexico) {
        callback(new Error(this.$t('validations.required', { thing: this.$t('billing-markets.sat-purpose-mexico') })))
      } else {
        callback()
      }
    },
    /**
     * Validate RFC Number
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateRfcMexico(_rule, value, callback) {
      if (!this.mxMarketFields.rfcMexico) {
        callback(new Error(this.$t('validations.required', { thing: this.$t('billing-markets.rfc-mexico') })))
      } else if (this.rfcMexicoPattern && !((new RegExp(this.rfcMexicoPattern)).test(this.mxMarketFields.rfcMexico))) {
        callback(new Error(this.rfcExample))
      } else {
        callback()
      }
    },
    onCompanyTypeChange() {
      if (!this.taxRegimeCodeMexicoOptions.includes(this.mxMarketFields.taxRegimeCodeMexico)) {
        this.mxMarketFields.taxRegimeCodeMexico = null
      }
      this.onTaxRegimeCodeMexicoChange()
    },
    onTaxRegimeCodeMexicoChange() {
      if (!this.satPurposeMexicoOptions.includes(this.mxMarketFields.satPurposeMexico)) {
        this.mxMarketFields.satPurposeMexico = null
      }
    },

    updateCurrentOverlay(val) {
      this.$emit('input', val)
    },

    async loadBalances() {
      if (!this.supply) {
        return
      }
      this.loadingEstimate = true
      this.balanceData = null
      try {
        this.balanceData = await sdk.instance.invoices().balance(this.companyId, this.currentMarket.id)
      } catch (error) {
        captureSentryError(error)
      } finally {
        this.loadingEstimate = false
      }
    },

    syncInvoices() {
      const vm = this

      // Handle if user clears dates
      if (!this.invoiceDateRange) {
        vm.invoices = []
        return
      }

      const fromDate = DateTime.fromMillis(Date.parse(this.invoiceDateRange[0])).toFormat('y-LL-dd')
      const toDate = DateTime.fromMillis(Date.parse(this.invoiceDateRange[1])).toFormat('y-LL-dd')
      vm.loadingInvoices = true

      sdk.instance
        .invoices()
        .get({
          supplierId: this.currentMarket.id,
          fromDate,
          toDate,
        })
        .then(res => {
          vm.invoices = res.invoices
            .sort((a, b) => Date.parse(b.dueDate) - Date.parse(a.dueDate))
            .map(v => {
              v.issueDate = DateTime.fromMillis(Date.parse(v.invoiceDate)).toFormat('dd/LL/y')
              v.csv = `${sdk.instance.baseurl}/v2/company/${this.companyId}/invoice/${v.invoiceId}/csv`
              v.pdf = `${sdk.instance.baseurl}/v2/company/${this.companyId}/invoice/${v.invoiceId}/pdf`
              return v
            })
        })
        .catch(e => {
          captureSentryError(e)
        })
        .finally(() => {
          vm.loadingInvoices = false
        })
    },
    vatRegisteredHandler() {
      if (!this.vatRegistered) {
        this.marketForm.taxNumber = null
      }
    },
    updateCardPaymentStore() {
      sdk.instance
        .stripe()
        .getPayments(this.currentMarket.id)
        .then(res => {
          this.cardPaymentsStore = res
        })
        .catch(e => {
          captureSentryError(e)
        })
    },
    showMarketForm() {
      this.showMarketStrategyMap[this.currentMarket.market]?.() ?? this.commonShowMarketStrategy()
    },
    updateRequiredFields() {
      // Update required validation for companyLegalName and companyLegalIdentifier for Enabled Markets
      this.$nextTick(() => {
        if (this.supply && this.supply.id) {
          this.marketRules.companyLegalIdentifier.required = false
          this.marketRules.companyLegalName.required = false
        } else {
          this.marketRules.companyLegalIdentifier.required = !this.mxMarket
          this.marketRules.companyLegalName.required = true
        }
      })
    },
    async mexicoShowMarketStrategy() {
      this.savingBillingMarket = true
      try {
        const { data } = await sdk.instance.supplierTaxRules(this.currentMarket.id)
        this.mexicoData = data
      } catch (error) {
        captureSentryError(error)
      } finally {
        this.savingBillingMarket = false
      }
      this.commonShowMarketStrategy()
      if (this.supply) {
        for (const field in this.mxMarketFields) {
          this.mxMarketFields[field] = this.supply?.countrySpecificTaxFields?.[field] ?? null
        }
      }
      this.setMexicoFields(true)
    },
    commonShowMarketStrategy() {
      if (!this.supply) {
        const marketForm = {
          currencyEnum: null,
          language: null,
          companyLegalIdentifier: null,
          companyLegalName: null,
          billingContactName: null,
          billingContactPhone: null,
          billingContactEmail: null,
          address1: null,
          address2: null,
          city: null,
          state: null,
          postcode: null,
          country: null,
          yourPoNumber: null,
          taxNumber: null,
          agreement: false,
        }
        if (this.currentMarket?.availableCurrencies?.length === 1) {
          marketForm.currencyEnum = this.currentMarket.availableCurrencies[0]
        }
        if (this.currentMarket?.availableInvoiceLanguages?.length) {
          const index = this.currentMarket.availableInvoiceLanguages.findIndex(item => {
            return item.toUpperCase() === 'EN'
          })
          if (index !== -1) {
            marketForm.language = this.currentMarket.availableInvoiceLanguages[index]
          }
        }

        this.marketForm = marketForm
      } else {
        this.marketForm = Object.assign(...Object.entries(this.marketForm).map(([key]) => {
          // handle VAT numbers on the way in (remove prefix)
          const pick = {}
          if (Object.prototype.hasOwnProperty.call(this.supply, key)) {
            if (key === 'taxNumber') {
              pick[key] = this.supply[key].slice(2)
            } else {
              pick[key] = this.supply[key]
            }
          } else {
            // set default value
            pick[key] = null
          }
          return pick
        }))
        this.marketForm.agreement = true
      }
      this.updateRequiredFields()
      this.setMexicoFields(false)

      this.checkMarket()
    },
    setMexicoFields(value = false) {
      this.marketRules.companyLegalIdentifier.required = !value
      if (!value) {
        for (const field in this.mxMarketFields) {
          this.mxMarketFields[field] = null
        }
      }
    },
    updateRFCSpecification(rfcSpecification) {
      this.$refs.rfcMexico?.clearValidate()
      if (!rfcSpecification) {
        this.mxMarketFields.rfcMexico = null
        return
      }
      if (rfcSpecification.defaultValue) {
        this.mxMarketFields.rfcMexico = rfcSpecification.defaultValue
      }
    },
    getInternationalizationString(fields = [], id = null) {
      let key = ''
      if (fields.length && id) {
        const keySuffix = kebabCase(id)
        key = `${fields.join('.')}-${keySuffix}`
      }
      return key
    },
    checkMarket() {
      // reset values
      this.euMarket = false
      this.euBillingMarket = false

      // reset the validation messages
      if (this.$refs.marketForm) {
        this.$refs.marketForm.clearValidate()
      }

      // Assign default finance onboarding answer based on the market
      this.mobqRadio = this.specialMarketRegionCode === 'UK' ? true : false
      // Persisting finance onboarding answer on edit
      const previousResponse = this.supply?.financeOnboarding?.response
      if (previousResponse && previousResponse !== 'NOT_APPLICABLE') {
        this.mobqRadio = this.supply.financeOnboarding.response === 'YES' ? true : false
      }

      // check if the country selected and the billing market are in the EU
      this.countryList.forEach(country => {
        // Is it an EU market?
        if (country.code === this.currentMarket.market && country.eu) {
          this.euMarket = country.eu
        }
        // Is the customer billing from an EU market?
        if (country.code === this.marketForm.country && country.eu) {
          this.euBillingMarket = country.eu
          this.countryRules = country.validation
        }
      })

      // Show / hide the vat input field if taxNumber
      if (this.euBillingMarket && this.euMarket) {
        this.vatRegistered = this.marketForm.taxNumber !== null
      }
    },

    submitMarketForm() {
      if (this.currentMarket.market === 'MX') {
        this.marketForm.countrySpecificTaxFields = Object.assign({}, this.marketForm.countrySpecificTaxFields, this.mxMarketFields)
      }
      if (this.currentMarket.market === 'US') {
        // Remove countrySpecificTaxFields object for distributor account
        if (this.isDistributorAccount) {
          this.marketForm.countrySpecificTaxFields = undefined
        } else {
          this.marketForm.countrySpecificTaxFields = Object.assign({}, this.marketForm.countrySpecificTaxFields, this.usMarketFields)
        }
      }

      this.$refs.marketForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          return
        }

        let submitData
        let editingExisting = false
        const taxDetails = {}

        // add/remove the tax number to the model
        if (this.euBillingMarket && this.euMarket && this.vatRegistered) {
          taxDetails.taxNumber = `${this.marketForm.country}${this.marketForm.taxNumber}`
        }

        if (this.supply) {
          editingExisting = true
          submitData = {
            ...this.supply,
            ...this.marketForm,
            ...taxDetails,
            companyLegalName: undefined,
            companyLegalIdentifier: undefined,
          }
          this.financeOnboarding.question ? submitData.financeOnboarding = { ...this.financeOnboarding } : delete submitData.financeOnboarding
        } else {
          submitData = {
            ...this.marketForm,
            ...taxDetails,
          }
          submitData.firstPartyId = this.currentMarket.id
          this.financeOnboarding.question ? submitData.financeOnboarding = { ...this.financeOnboarding } : {}
        }

        this.savingBillingMarket = true
        this.postMarketSupply(submitData)
          .then(() => {
            sdk.instance.resetPriceBookCache()
            this.savingBillingMarket = false
            // If they are adding a new one, push them back to the main screen
            if (!editingExisting) {
              this.$router.push('/company/markets', () => {
                // empty function is intentional
              })
            }
          })
          .catch(e => {
            console.error(e)
            this.savingBillingMarket = false
          })
      })
    },
  },
}

</script>

<style lang="scss" scoped>
.billing-market {
  &-panel {
    border: 1px solid var(--border-color-base);
    border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
    padding: 1rem;
  }
  &-next-invoice {
    -ms-grid-columns: 1fr 150px;
    grid-template-columns: 1fr 150px;
    &-hr {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

.downloadLink {
  text-decoration: none;
}
.download-icon {
  cursor: pointer;
  height: 2rem;
  width: auto;
}

p.date-range {
  font-weight: 700;
}

.width-225px {
  width: 225px;
}

#agreement {
  vertical-align: top;

  ::v-deep .el-form-item__content {
    line-height: inherit;
    margin-left: 0 !important;

    .notices {
      border: 1px solid var(--color-warning);
      border-radius: var(--border-radius-base);
      margin-top: 1rem;
      padding: 1rem 2rem;

      strong {
        margin-left: 28px;
      }

      .el-checkbox {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
