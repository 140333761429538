import captureSentryError from '@/utils/CaptureSentryError.js'
import { MEGAPORT_ASN } from '@/Globals.js'

/**
 * Validate the ASN according to the required rules. There are base rules for making sure that the shape
 * of the value is correct, and then modifications depending on what type of thing we are checking.
 *
 * The following are the known types and ranges:
 *  BGP connections (Peer ASN and Override MCR ASN): 1 - 4294967294 as per this ticket
 *  IX and AMS-IX: 2-64495 or 131072-4199999999
 *  AWS VIF: 2-4199999999
 *  MCR: 2-4294967295
 *  IBM: 1-64495, 64999, 131072-4199999999, 4201000000-4201064511
 *  All other connections e.g. Azure, Salesforce: 2-4294967295
 *
 * @param {object} rule - as for base validation
 * @param {string|number} value - as for base validation
 * @param {function} callback - as for base validation
 * @param {string} type - so we can apply the right rules for each type of connection
 */
const validateAsnCore = (rule, value, callback, type) => {
  let minAsn = 2
  let maxAsn = Math.pow(2, 32) - 1
  let minAsn2 = null
  let maxAsn2 = null
  let minAsn3 = null
  let maxAsn3 = null
  let defaultAsn = null

  switch (type) {
    case 'AWS':
      maxAsn = 4199999999
      break
    case 'IX':
      maxAsn = 64495
      minAsn2 = 131072
      maxAsn2 = 4199999999
      break
    case 'BGP':
      minAsn = 1
      maxAsn = Math.pow(2, 32) - 2
      break
    case 'OUTSCALE':
      minAsn = 64512
      maxAsn = 65534
      break
    case 'IBM':
      minAsn = 1
      maxAsn = 64495
      defaultAsn = 64999
      minAsn2 = 131072
      maxAsn2 = 4199999999
      minAsn3 = 4201000000
      maxAsn3 = 4201064511
      break
    case 'MCR':
      defaultAsn = MEGAPORT_ASN
      break
    case 'DEFAULT':
      break
    default:
      throw new Error(`Don't understand ASN type ${type}`)
  }

  const asnRegex = /^(ASN?)(\d+)$/i
  let numericValue = null
  if (typeof value === 'number') {
    numericValue = value
  } else if (typeof value === 'string') {
    if (/^\d+$/.test(value)) {
      numericValue = parseInt(value)
    } else if (value.match(asnRegex)) {
      numericValue = parseInt(value.match(asnRegex)[2])
    } else if (value !== '') {
      callback(window.mpApp.$t('validations.asn'))
      return
    }
  } else if (value) {
    captureSentryError(new Error(`Can't validate value of type: ${typeof value}`))
  }

  if (rule.required && !numericValue) {
    callback(window.mpApp.$t('validations.required', { thing: 'ASN' }))
  } else if (!rule.required && numericValue === null) {
    callback()
  } else if (minAsn3 && maxAsn3 && defaultAsn) {
    // Currently only used for IBM
    if (
      !validateIntegerBetweenValues(numericValue, minAsn, maxAsn) &&
      !validateIntegerBetweenValues(numericValue, minAsn2, maxAsn2) &&
      !validateIntegerBetweenValues(numericValue, minAsn3, maxAsn3) &&
      numericValue !== defaultAsn
    ) {
      callback(window.mpApp.$t('validations.asn-validation-range-triple', { minAsn, maxAsn, minAsn2, maxAsn2, minAsn3, maxAsn3, defaultAsn }))
    } else {
      callback()
    }
  } else if (minAsn2 && maxAsn2) {
    if (
      !validateIntegerBetweenValues(numericValue, minAsn, maxAsn) &&
      !validateIntegerBetweenValues(numericValue, minAsn2, maxAsn2)
    ) {
      callback(window.mpApp.$t('validations.asn-validation-range', { minAsn, maxAsn, minAsn2, maxAsn2 }))
    } else {
      callback()
    }
  } else if (!validateIntegerBetweenValues(numericValue, minAsn, maxAsn)) {
    if (defaultAsn) {
      callback(window.mpApp.$t('validations.asn-validation', { maxAsn, defaultAsn }))
    } else {
      callback(window.mpApp.$t('validations.asn-validation-plain', { minAsn, maxAsn }))
    }
  } else {
    callback()
  }
}

const validateIntegerBetweenValues = (value, min, max) => {
  if (value < min || value > max || value % 1 !== 0) {
    return false
  }
  return true
}

const validateAwsAsn = (rule, value, callback) => {
  validateAsnCore(rule, value, callback, 'AWS')
}
const validateIxAsn = (rule, value, callback) => {
  validateAsnCore(rule, value, callback, 'IX')
}
const validateBgpAsn = (rule, value, callback) => {
  validateAsnCore(rule, value, callback, 'BGP')
}
const validateOutscaleAsn = (rule, value, callback) => {
  validateAsnCore(rule, value, callback, 'OUTSCALE')
}
const validateIbmAsn = (rule, value, callback) => {
  validateAsnCore(rule, value, callback, 'IBM')
}
const validateMcrAsn = (rule, value, callback) => {
  validateAsnCore(rule, value, callback, 'MCR')
}
const validateAsnDefault = (rule, value, callback) => {
  validateAsnCore(rule, value, callback, 'DEFAULT')
}

const validatePassword = (rule, value, callback, passwordAnalysis) => {
  const hasLowerCase = /[a-z]/
  const hasUpperCase = /[A-Z]/
  const hasNumber = /[0-9]/
  const hasSymbol = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/

  if (value.length < 8) {
    callback(window.mpApp.$tc('validations.min-length', 8, { min: 8 }))
  } else if (value.length > 99) {
    callback(window.mpApp.$tc('validations.max-length', 99, { max: 99 }))
  } else if (!hasLowerCase.test(value)) {
    callback(window.mpApp.$tc('validations.min-lower-case'))
  } else if (!hasUpperCase.test(value)) {
    callback(window.mpApp.$tc('validations.min-upper-case'))
  } else if (!hasNumber.test(value)) {
    callback(window.mpApp.$tc('validations.min-number'))
  } else if (!hasSymbol.test(value)) {
    callback(window.mpApp.$tc('validations.min-symbol'))
  } else if (passwordAnalysis.score < 3 && value.length !== 8) {
    callback(window.mpApp.$t('validations.weak-password'))
  } else if (passwordAnalysis.score < 2 && value.length === 8) {
    callback(window.mpApp.$t('validations.weak-password'))
  } else {
    callback()
  }
}

const MAC_ADDRESS_REGEX = /^(([0-9A-Fa-f]{2}:){5}([0-9A-Fa-f]{2})|([0-9A-Fa-f]{2}-){5}([0-9A-Fa-f]{2})|([0-9A-Fa-f]{3}\.){3}([0-9A-Fa-f]{3})|([0-9A-Fa-f]{4}\.){2}([0-9A-Fa-f]{4})|([0-9A-Fa-f]{12}))$/

const PHONE_NUMBER_REGEX = /^(?:\+?\d{1,6}|\(\d{1,5}\))(([-. ]?(?:\(\d{1,5}\)|\d{1,4})){1,5})$/

// This accepts an IP address with CIDR notation with the subnet mask optionally as a dotted quad
// Note that this is purposely a string due to the way it's used throughout the app
const IP_CIDR_REGEX = '[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}/[0-9]{1,3}(?:\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3})?'

/**
 * check if the entered MAC address contains 12 hex characters
 */
const validateMacAddress = (rule, value, callback) => {
  if (rule.required && !value) {
    callback(window.mpApp.$t('validations.required', { thing: 'MAC Address' }))
  } else if (value && !MAC_ADDRESS_REGEX.test(value)) {
    callback(window.mpApp.$t('validations.invalid-mac'))
  } else {
    callback()
  }
}

const validateBGPPassword = (rule, value, callback) => {
  const BGP_PASSWORD_REGEX = /^[a-zA-Z0-9!@#?.$%^&*+=-]*$/

  if (rule.required && !value) {
    callback(window.mpApp.$t('validations.required', { thing: 'BGP Password' }))
  } else if (value && value.length < 1) {
    callback(window.mpApp.$tc('validations.min-length', 1, { min: 1 }))
  } else if (value && !BGP_PASSWORD_REGEX.test(value)) {
    callback(window.mpApp.$t('validations.bgp-password-valid-characters'))
  } else if (value && value.length > 25) {
    callback(window.mpApp.$tc('validations.max-length', 25, { max: 25 }))
  } else {
    callback()
  }
}

const validateIxPeerMacro = (_rule, value, callback) => {
  if (value && !/^[a-zA-Z0-9-:_]*$/.test(value)) {
    callback(window.mpApp.$t('validations.ix-peer-invalid-characters'))
  } else if (value && !/^AS[a-zA-Z0-9_-]+(:AS[a-zA-Z0-9_-]+)?$/.test(value)) {
    callback(window.mpApp.$t('validations.ix-peer-invalid-format'))
  } else {
    callback()
  }
}

const validateReverseDns = (_rule, value, callback) => {
  const REVERSE_DNS_REGEX = /^[a-zA-Z0-9-.]*$/
  const MAX_LENGTH = 63

  if (value) {
    if (!REVERSE_DNS_REGEX.test(value)) {
      callback(window.mpApp.$t('validations.reverse-dns-invalid-characters'))
      return
    } else if (value.length > MAX_LENGTH) {
      callback(window.mpApp.$tc('validations.max-length', MAX_LENGTH, { max: MAX_LENGTH }))
      return
    }
  }

  callback()
}

export {
  validateAsnDefault,
  validateAwsAsn,
  validateIxAsn,
  validateBgpAsn,
  validateOutscaleAsn,
  validateIbmAsn,
  validateMcrAsn,
  validatePassword,
  validateMacAddress,
  validateBGPPassword,
  validateIxPeerMacro,
  validateReverseDns,
  PHONE_NUMBER_REGEX,
  IP_CIDR_REGEX,
}
