import captureSentryError from '@/utils/CaptureSentryError'
import sdk from '@megaport/api-sdk'

/**
 * Downloads a file from the specified URL.
 * @param {string} url - The URL of the file to download.
 * @param {Object} options
 * @param {boolean} options.openInNewTab - Whether to open the file in a new tab. Default is false.
 * @param {Object} options.additionalHeaders - Any extra headers that are required, for example to change the accept header
 * @returns {Promise<void>} - A promise that resolves when the file is downloaded successfully.
 */
export const downloadFile = async (url, { openInNewTab = false, additionalHeaders = {} } = {}) => {
  const accessToken = sdk.instance.api.bearerToken
  const sessionToken = sdk.instance.api.authToken

  // If we have an accessToken (JWT) use the Authorization header, otherwise fall back to support DLR session tokens :(
  const authHeader = accessToken
    ? { Authorization: `Bearer ${accessToken}` }
    : { 'X-Auth-Token': sessionToken }

  const headers = { ...authHeader, ...additionalHeaders }

  // Patch in call context header when in managed context
  if (sdk.instance.api.callContext) {
    headers['X-Call-Context'] = sdk.instance.api.callContext
  }

  try {
    const response = await fetch(url, { headers })
    if (!response.ok) throw new Error(response.statusText)

    const data = await response.blob()

    if (openInNewTab) {
      openFile(data)
      return
    }

    const filename = response.headers.get('content-disposition')
      .split?.(';')
      .find(n => n.includes('filename='))
      .replace('filename=', '')
      .trim()

    triggerDownload(data, filename)
  } catch (e) {
    window.mpApp.$alert(
      window.mpApp.$t('general.download-failed'),
      window.mpApp.$t('general.download-failed-title'),
      { customClass: 'error-alert' }
    )
    captureSentryError(e)
  }
}

/**
 * This takes a blob, creates an <a> element and clicks it to trigger the download
 * @param {string} data - The blob data to download
 * @param {string} filename - The name of the file to download
 */
export const triggerDownload = (data, filename) => {
  const url = window.URL.createObjectURL(data)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()

  setTimeout(() => {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, 0)
}

export const openFile = file => {
  const url = window.URL.createObjectURL(file)
  window.open(url, '_blank')
}
