<template>
  <div>
    <el-collapse-transition v-if="!activePanel">
      <div class="button-row">
        <template v-if="registeredCard.id">
          <el-button name="makePayment"
            @click="activePanel = 'makePayment'">
            {{ $t('billing-markets.payment') }}
          </el-button>
          <el-button name="removeCard"
            @click="activePanel = 'removeCard'">
            {{ $t('billing-markets.remove-card-ending', { numbers: registeredCard.last4 }) }}
          </el-button>
          <el-button name="updateCard"
            @click="activePanel = 'updateCard'">
            {{ $t('billing-markets.register-card-edit') }}
          </el-button>
        </template>
        <template v-else>
          <el-button name="registerCard"
            @click="activePanel = 'registerCard'">
            {{ $t('billing-markets.register-card') }}
          </el-button>
          <el-button name="makeOneOffPayment"
            @click="activePanel = 'makeOneOffPayment'">
            {{ $t('billing-markets.payment-once') }}
          </el-button>
        </template>
      </div>
    </el-collapse-transition>
    <el-collapse-transition>
      <div v-if="activePanel === 'registerCard' || activePanel === 'makePayment' || activePanel === 'makeOneOffPayment'">
        <h3 class="text-align-center mt-1 mb-2">
          {{ activePanel === 'registerCard' ? $t('billing-markets.register-card') : $t('billing-markets.payment') }}
        </h3>

        <div v-loading="processingCardAndPaymentForm"
          class="payment-form-container"
          :element-loading-text="activePanel === 'registerCard' ? $t('billing-markets.registering-card') : $t('billing-markets.payment-processing')">
          <el-form ref="cardAndPaymentForm"
            :model="cardAndPaymentForm"
            :rules="cardAndPaymentRules"
            label-width="160px">
            <template v-if="activePanel === 'registerCard' || activePanel === 'makeOneOffPayment'">
              <p>{{ cardFormMessage }}</p>

              <el-form-item prop="cardNumberError"
                :label="$t('billing-markets.card-number')"
                class="cc-input">
                <div class="el-input">
                  <div id="cardAndPaymentForm-cardNumberInput"
                    class="el-input__inner" />
                </div>
              </el-form-item>
              <el-form-item prop="cardExpiryError"
                :label="$t('billing-markets.card-expiry')"
                class="cc-input">
                <div class="el-input">
                  <div id="cardAndPaymentForm-cardExpiryInput"
                    class="el-input__inner" />
                </div>
              </el-form-item>
              <el-form-item prop="cardCVCError"
                :label="$t('billing-markets.card-cvc')"
                class="cc-input">
                <div class="el-input">
                  <div id="cardAndPaymentForm-cardCVCInput"
                    class="el-input__inner" />
                </div>
              </el-form-item>

              <template v-if="activePanel === 'registerCard'">
                <el-form-item prop="autoPay">
                  <el-switch v-model="cardAndPaymentForm.autoPay"
                    :active-text="$t('billing-markets.auto-payments-on')"
                    :inactive-text="$t('billing-markets.auto-payments-off')" />
                </el-form-item>
              </template>
            </template>
            <p v-else>
              {{ $t('billing-markets.pay-card-ending', { numbers: registeredCard.last4 }) }}
            </p>

            <template v-if="activePanel !== 'registerCard'">
              <el-form-item prop="amount"
                :label="$t('billing-markets.payment-amount')">
                <el-input v-model="cardAndPaymentForm.amount"
                  data-testid="payment-amount">
                  <template #append>
                    {{ marketObj.supply.currencyEnum }}
                  </template>
                </el-input>
              </el-form-item>

              <p v-if="marketObj.supply"
                class="text-align-center">
                {{ accountBalanceString[0] }}
                <strong>{{ marketObj.supply.accountBalance | formatInCurrency(marketObj.supply.currencyEnum) }}</strong>
                {{ accountBalanceString[1] }}
              </p>

              <el-form-item prop="description"
                :label="$t('billing-markets.payment-description')">
                <el-input v-model="cardAndPaymentForm.description"
                  data-testid="payment-description" />
              </el-form-item>
            </template>

            <!-- message for all countries -->
            <p v-if="activePanel === 'registerCard' && cardAndPaymentForm.autoPay"
              data-name="autopay">
              {{ $t('billing-markets.cc-autopay') }}
            </p>
            <p data-name="processing-fees">
              {{ $t('billing-markets.processing-fees') }}
            </p>
            <i18n path="billing-markets.payment-docs-html"
              tag="p"
              data-name="payment-docs">
              <template #link>
                <a href="https://docs.megaport.com/finance/credit-card-payments/"
                  target="_blank">{{ $t('general.here') }}</a>
              </template>
            </i18n>
            <i18n path="billing-markets.cc-support-html"
              tag="p"
              data-name="support">
              <template #email>
                <a href="mailto:ar.global@megaport.com"
                  target="_blank">{{ $t('companyInfo.arGlobalEmail') }}</a>
              </template>
            </i18n>
          </el-form>

          <!-- ELSE -->
        </div>
      </div>
    </el-collapse-transition>
    <el-collapse-transition>
      <div v-if="activePanel === 'updateCard'"
        v-loading="updatingCard"
        class="text-align-center mb-2"
        :element-loading-text="$t('general.updating-details')">
        <h3 class="mt-1 mb-2">
          {{ $t('billing-markets.edit-card-ending', { numbers: registeredCard.last4 }) }}
        </h3>
        <el-switch v-model="cardAndPaymentForm.autoPay"
          :active-text="$t('billing-markets.auto-payments-on')"
          :inactive-text="$t('billing-markets.auto-payments-off')" />
        <div class="text-align-left">
          <p v-if="cardAndPaymentForm.autoPay"
            data-name="autopay">
            {{ $t('billing-markets.cc-autopay') }}
          </p>
          <p data-name="processing-fees">
            {{ $t('billing-markets.processing-fees') }}
          </p>
          <i18n path="billing-markets.payment-docs-html"
            tag="p"
            data-name="payment-docs">
            <template #link>
              <a href="https://docs.megaport.com/finance/credit-card-payments/"
                target="_blank">{{ $t('general.here') }}</a>
            </template>
          </i18n>
          <i18n path="billing-markets.cc-support-html"
            tag="p"
            data-name="support">
            <template #email>
              <a href="mailto:ar.global@megaport.com"
                target="_blank">{{ $t('companyInfo.arGlobalEmail') }}</a>
            </template>
          </i18n>
        </div>
      </div>
    </el-collapse-transition>
    <el-collapse-transition>
      <div v-if="activePanel === 'removeCard'"
        v-loading="removingCard"
        class="text-align-center mb-2"
        :element-loading-text="$t('billing-markets.removing-card')">
        <h3 class="text-align-center mt-1 mb-2">
          {{ $t('billing-markets.remove-card') }}
        </h3>
        <p>{{ $t('billing-markets.remove-card-confirmation', { numbers: registeredCard.last4, marketName: marketObj.country }) }}</p>
      </div>
    </el-collapse-transition>

    <el-collapse-transition>
      <div v-if="activePanel"
        class="text-align-right">
        <el-button name="cancel"
          @click="activePanel = null">
          {{ $t('general.cancel') }}
        </el-button>
        <el-button v-if="activePanel === 'registerCard'"
          type="primary"
          name="registerCardConfirmation"
          data-testid="stripeConfirmButton"
          @click="handleRegisterCard">
          {{ $t('billing-markets.register-card') }}
        </el-button>
        <el-button v-if="activePanel === 'updateCard'"
          type="primary"
          name="updateCardConfirmation"
          data-testid="stripeConfirmButton"
          @click="handleUpdateCard">
          {{ $t('billing-markets.update-card') }}
        </el-button>
        <el-button v-if="activePanel === 'removeCard'"
          type="warning"
          name="removeCardConfirmation"
          data-testid="stripeConfirmButton"
          @click="handleRemoveCard">
          {{ $t('billing-markets.remove-card') }}
        </el-button>
        <el-button v-if="activePanel === 'makePayment'"
          :disabled="processingCardAndPaymentForm"
          type="primary"
          name="makePaymentConfirmation"
          data-testid="stripeConfirmButton"
          @click="handleMakePayment">
          {{ $t('billing-markets.payment') }}
        </el-button>
        <el-button v-if="activePanel === 'makeOneOffPayment'"
          type="primary"
          name="makePaymentConfirmation"
          data-testid="stripeConfirmButton"
          @click="handleMakeOneOffPayment">
          {{ $t('billing-markets.payment-once') }}
        </el-button>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import captureSentryError from '@/utils/CaptureSentryError.js'
import { readCssVar } from '@/utils/CssVars.js'
import { moneyFilter } from '@/helpers.js'
import sdk from '@megaport/api-sdk'

const STRIPE_ERROR_STATUS_CODE = 424
const REQUIRE_AUTH_STATUS_CODE = 402

export default {
  name: 'StripeComponent',

  filters: {
    formatInCurrency: moneyFilter,
  },

  props: {
    marketId: {
      type: Number,
      required: true,
    },
  },

  // The intent with the stripe payment processing is to integrate it into the form by having the form think it's
  // dealing with an input of the error message and it will display that if there is an error. The actual Stripe input
  // will be registered and we will use event listeners to gather and process the data.
  data() {
    const validateCCInput = (rule, value, callback) => {
      if (this.cardAndPaymentForm.validatingForm && value === 'NOT_FILLED') {
        callback(this.$t('validations.required', { thing: this.$t('billing-markets.payment-amount') }))
      } else if (value.length && value !== 'NOT_FILLED') {
        callback(value)
      } else {
        callback()
      }
    }

    const validateAmount = (rule, value, callback) => {
      const amount = Number(value)
      if (!value) {
        callback(this.$t('validations.required', { thing: this.$t('billing-markets.payment-amount') }))
      } else if (Number.isNaN(amount)) {
        callback(this.$t('validations.payment-enter-amount'))
      } else if (amount < 1) {
        callback(this.$t('validations.minimum-payment', { value: 1 }))
      } else {
        callback()
      }
    }

    const validatePaymentDescription = (rule, value, callback) => {
      if (rule.required && !value) {
        callback(this.$t('validations.required', { thing: this.$t('billing-markets.payment-description') }))
      } else if (value.length > 22) {
        callback(this.$tc('validations.max-length', 22, { max: 22 }))
      } else if (!/^[^'<>"]*$/.test(value)) {
        callback(this.$t('validations.characters-not-allowed', { chars: '\' < > "' }))
      } else if (value && !/^.*[a-zA-Z].*$/.test(value)) {
        callback(this.$t('validations.payment-description-none'))
      } else {
        callback()
      }
    }

    return {
      activePanel: null,
      registeredCard: {},

      cardAndPaymentForm: {
        stripe: null, // Used to store the instance of Stripe we are working with
        validatingForm: false, // So we can validate the fields differently during submit
        autoPay: true,
        cardNumberDummy: '',
        cardNumberInput: null, // Will be replaced by Stripe element
        cardNumberError: '',
        cardNumberComplete: false,
        cardExpiryInput: null, // Will be replaced by Stripe element
        cardExpiryError: '',
        cardExpiryComplete: false,
        cardCVCInput: null, // Will be replaced by Stripe element
        cardCVCError: '',
        cardCVCComplete: false,
        // The following are for payments
        amount: null,
        description: '',
      },
      cardAndPaymentRules: {
        cardNumberError: { required: true, validator: validateCCInput, trigger: 'change' },
        cardExpiryError: { required: true, validator: validateCCInput, trigger: 'change' },
        cardCVCError: { required: true, validator: validateCCInput, trigger: 'change' },
        amount: { required: true, validator: validateAmount, trigger: 'blur' },
        description: { validator: validatePaymentDescription, trigger: 'blur' },
      },
      processingCardAndPaymentForm: false,
      updatingCard: false,
      removingCard: false,

      stripeElementStyles: {
        base: {
          color: readCssVar('--color-text-regular'),
          fontWeight: readCssVar('--mp-input-font-weight'),
          fontFamily: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
          // Note that since Stripe creates its own shadow dom, we need to convert it as per our global scaling
          // and hand it off as px. So 1.4rem => 16px * 62.5% => 14px
          fontSize: '14px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: readCssVar('--color-text-placeholder'),
          },
        },
        invalid: {
          color: readCssVar('--color-text-regular'),
        },
      },
    }
  },

  computed: {
    accountBalanceString() {
      const translated = this.$t('billing-markets.account-balance')
      const split = translated.split('|')
      if (split.length !== 2) {
        captureSentryError(new Error(`Error splitting translated string ${translated} into two parts`))
        return null
      }
      return split
    },
    marketObj() {
      const market = this.$store.state.Markets.markets.find(m => m.id === this.marketId) || {}
      return market
    },
    stripeKey() {
      if (this.marketObj && this.marketObj.supply && this.marketObj.supply.stripeAccountPublishableKey) {
        return this.marketObj.supply.stripeAccountPublishableKey
      }
      return false
    },
    cardFormMessage() {
      if (this.activePanel === 'registerCard') {
        return this.$t('billing-markets.register-card-message')
      }
      return this.$t('billing-markets.payment-once-make')
    },
  },

  watch: {
    activePanel: {
      immediate: true,
      handler(newVal, oldVal) {
        if (['makePayment', 'registerCard', 'makeOneOffPayment'].includes(oldVal)) {
          this.tearDownStripeForm()
        }
        if (['makePayment', 'registerCard', 'makeOneOffPayment'].includes(newVal)) {
          this.$nextTick(() => {
            this.setupStripeForm()
          })
        }
        if (newVal === 'updateCard') {
          // Reuse part of the data from the register card form.
          this.cardAndPaymentForm.autoPay = this.registeredCard.autoPay
        }
      },
    },
  },

  created() {
    sdk.instance
      .stripe()
      .getCards(this.marketId)
      .then(res => {
        this.registeredCard = res
      })
      .catch(e => {
        // 404 responses are expected when there are no cards registered.
        if (e.status !== 404) {
          captureSentryError(e)
        }
      })
  },

  methods: {
    setupStripeForm() {
      // Configure the form
      this.cardAndPaymentForm = {
        stripe: null,
        validatingForm: false,
        autoPay: true,
        cardNumberDummy: '',
        cardNumberInput: null,
        cardNumberError: 'NOT_FILLED',
        cardNumberComplete: false,
        cardExpiryInput: null,
        cardExpiryError: 'NOT_FILLED',
        cardExpiryComplete: false,
        cardCVCInput: null,
        cardCVCError: 'NOT_FILLED',
        cardCVCComplete: false,
        amount: null,
        description: '',
      }
      if (this.activePanel === 'makePayment' || this.activePanel === 'makeOneOffPayment') {
        this.cardAndPaymentForm.amount = this.marketObj.supply.accountBalance
        this.cardAndPaymentForm.description = ''
      }

      // Create a stripe instance using the required key
      this.cardAndPaymentForm.stripe = window.Stripe(this.stripeKey)

      if (this.activePanel !== 'makePayment') {
        // Create an elements instance - collects the stripe data
        const elements = this.cardAndPaymentForm.stripe.elements({
          fonts: [
            {
              cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:400',
            },
          ],
        })

        // Create the stripe objects
        this.cardAndPaymentForm.cardNumberInput = elements.create('cardNumber', {
          style: this.stripeElementStyles,
        })
        this.cardAndPaymentForm.cardExpiryInput = elements.create('cardExpiry', {
          style: this.stripeElementStyles,
        })
        this.cardAndPaymentForm.cardCVCInput = elements.create('cardCvc', {
          style: this.stripeElementStyles,
        })
        // Mount them in the appropriate places
        this.cardAndPaymentForm.cardNumberInput.mount('#cardAndPaymentForm-cardNumberInput')
        this.cardAndPaymentForm.cardExpiryInput.mount('#cardAndPaymentForm-cardExpiryInput')
        this.cardAndPaymentForm.cardCVCInput.mount('#cardAndPaymentForm-cardCVCInput')
        // Set up the listeners
        this.cardAndPaymentForm.cardNumberInput.addEventListener('change', this.listenForRegisterCardNumber)
        this.cardAndPaymentForm.cardExpiryInput.addEventListener('change', this.listenForRegisterCardExpiry)
        this.cardAndPaymentForm.cardCVCInput.addEventListener('change', this.listenForRegisterCardCVC)
      }
    },
    tearDownStripeForm() {
      if (this.activePanel !== 'makePayment') {
        this.cardAndPaymentForm.cardNumberInput?.removeEventListener('change', this.listenForRegisterCardNumber)
        this.cardAndPaymentForm.cardExpiryInput?.removeEventListener('change', this.listenForRegisterCardExpiry)
        this.cardAndPaymentForm.cardCVCInput?.removeEventListener('change', this.listenForRegisterCardCVC)
      }
      this.cardAndPaymentForm.stripe = null
    },
    listenForRegisterCardNumber(event) {
      this.cardAndPaymentForm.cardNumberComplete = event.complete
      if (event.error) {
        this.cardAndPaymentForm.cardNumberError = event.error.message
      } else if (!event.complete) {
        this.cardAndPaymentForm.cardNumberError = 'NOT_FILLED'
      } else {
        this.cardAndPaymentForm.cardNumberError = ''
      }
      this.$refs.cardAndPaymentForm.validateField('cardNumberError')
    },
    listenForRegisterCardExpiry(event) {
      this.cardAndPaymentForm.cardExpiryComplete = event.complete
      if (event.error) {
        this.cardAndPaymentForm.cardExpiryError = event.error.message
      } else if (!event.complete) {
        this.cardAndPaymentForm.cardExpiryError = 'NOT_FILLED'
      } else {
        this.cardAndPaymentForm.cardExpiryError = ''
      }
      this.$refs.cardAndPaymentForm.validateField('cardExpiryError')
    },
    listenForRegisterCardCVC(event) {
      this.cardAndPaymentForm.cardCVCComplete = event.complete
      if (event.error) {
        this.cardAndPaymentForm.cardCVCError = event.error.message
      } else if (!event.complete) {
        this.cardAndPaymentForm.cardCVCError = 'NOT_FILLED'
      } else {
        this.cardAndPaymentForm.cardCVCError = ''
      }
      this.$refs.cardAndPaymentForm.validateField('cardCVCError')
    },

    /**
     * User initiated method to register a card.
     */
    async handleRegisterCard() {
      const valid = await this.validatePaymentForm()
      if (!valid) {
        return false
      }

      this.processingCardAndPaymentForm = true

      try {
        const paymentMethod = await this.createPaymentMethod()
        if (paymentMethod) {
          const registrationPayload = {
            paymentMethodId: paymentMethod.id,
            supplierId: this.marketObj?.id,
            autoPay: this.cardAndPaymentForm.autoPay,
          }
          const registerCardResponse = await sdk.instance.stripe().registerCard(registrationPayload)
          if (registerCardResponse.setupIntent.intentStatus === 'succeeded') {
            this.cardRegistrationSucceeded(registerCardResponse.card)
          } else {
            const props = {
              title: this.$t('billing-markets.register-card-fail'),
              message: this.$t('billing-markets.register-card-fail-message'),
              type: 'error',
              duration: 3000,
            }
            this.$notify(props)
          }
        }
      } catch (error) {
        if (error.status === REQUIRE_AUTH_STATUS_CODE) {
          const { setupIntent } = error.data.data
          const { confirmed = false, requiresAuth = false } = setupIntent
          if (!confirmed && requiresAuth) {
            await this.authenticateNewRegisteredCard(setupIntent)
            return true
          }
        }
        this.cardRegistrationFailed(error)
      } finally {
        this.processingCardAndPaymentForm = false
      }
    },

    /**
     * Validate the form. Used when registering a card, and when making a payment.
     * Displays an error message if there is an error validating the form.
     *
     * @returns Promise which resolves true on valid and resolves false on invalid.
     */
    validatePaymentForm() {
      return new Promise(resolve => {
        this.cardAndPaymentForm.validatingForm = true
        this.$refs.cardAndPaymentForm.validate(valid => {
          this.cardAndPaymentForm.validatingForm = false

          if (valid) {
            resolve(true)
          } else {
            const props = {
              title: this.$t('validations.failed'),
              message: this.$t('validations.correct-issues'),
              type: 'error',
              duration: 3000,
            }
            this.$notify(props)
            resolve(false)
          }
        })
      })
    },

    /**
     * Create a Stripe payment method. Displays an error message if it is unable to create
     * the payment method.
     *
     * @returns paymentMethod on success, null on failure
     */
    async createPaymentMethod() {
      try {
        const payload = {
          type: 'card',
          card: this.cardAndPaymentForm.cardNumberInput,
        }
        const { paymentMethod } = await this.cardAndPaymentForm.stripe.createPaymentMethod(payload)
        if (!paymentMethod) {
          throw new Error(this.$t('billing-markets.no-payment-method'))
        }
        return paymentMethod
      } catch (error) {
        if (error.status === STRIPE_ERROR_STATUS_CODE) {
          this.displayStripeError(error.data)
        } else {
          const props = {
            title: this.$t('billing-markets.payment-create-failed'),
            message: this.$t('billing-markets.payment-create-failed-message', { error: error.message }),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
        }
        return null
      }
    },

    /**
     * User has successfully registered a card.
     */
    cardRegistrationSucceeded(card) {
      this.registeredCard = card || {}
      this.activePanel = null

      this.$store.commit('Notifications/notifyGood', {
        title: this.$t('billing-markets.register-card-success'),
      })
    },

    /**
     * Registration of a card failed.
     *
     * @param {Object|string} err The error object with status and data.message, or a string
     */
    cardRegistrationFailed(err) {
      if (err.status === STRIPE_ERROR_STATUS_CODE) {
        this.displayStripeError(err.data)
      } else {
        const props = {
          title: this.$t('billing-markets.save-card-fail'),
          message: this.$t('billing-markets.save-card-fail-message', { error: err.data?.message || err }),
          type: 'error',
          duration: 3000,
        }
        this.$notify(props)

        this.$store.commit('Notifications/notifyBad', {
          title: this.$t('billing-markets.register-card-fail'),
          message: err.data?.message || err,
        })
      }
    },

    /**
     * Try to authenticate the card. Any authentication failures are handled within the
     * method.
     *
     * @param {Object} setupIntent The setupIntent from the Stripe registerCard response
     */
    async authenticateNewRegisteredCard(setupIntent) {
      const { clientSecret } = setupIntent

      try {
        // Call Stripe to try to confirm the card - will present UI to user
        const setupResult = await this.cardAndPaymentForm.stripe.handleCardSetup(clientSecret)

        // This happens if the user denies the authentication
        if (setupResult.error) {
          this.cardRegistrationFailed(setupResult.error.message)
          return false
        }

        // Something funky happened and the setupintent was not confirmed
        if (setupResult.setupIntent.status !== 'succeeded') {
          this.cardRegistrationFailed(this.$t('billing-markets.register-card-status', { status: setupResult.setupIntent.status }))
          return false
        }

        // Card was confirmed successfully - inform our API
        const { intentId = '', resourceId } = setupIntent
        const confirmationPayload = {
          intentId,
          supplierId: this.marketObj?.id,
        }
        const confirmationResult = await sdk.instance.stripe().registerCardConfirmation(resourceId, confirmationPayload)
        const { confirmed, requiresAuth } = confirmationResult.setupIntent
        if (confirmed && !requiresAuth) {
          // Everything worked fine
          this.cardRegistrationSucceeded(confirmationResult.card)
        } else {
          // Something funky happened and our API was not able to process the confirmation
          this.cardRegistrationFailed(this.$t('billing-markets.payment-confirm-fail'))
        }
      } catch (error) {
        this.cardRegistrationFailed(error)
      }
    },

    /**
     * User initiated method to make payment on a registered card.
     */
    async handleMakePayment() {
      const valid = await this.validatePaymentForm()
      if (!valid) {
        return false
      }

      this.processingCardAndPaymentForm = true

      try {
        const paymentPayload = {
          supplierId: this.marketObj?.id,
          amount: this.cardAndPaymentForm.amount,
          description: this.cardAndPaymentForm.description,
        }
        const cardId = this.registeredCard.id
        const stripeInstance = sdk.instance.stripe()
        const { intentStatus } = await stripeInstance.makeRegisteredCardPayment(cardId, paymentPayload)

        if (intentStatus === 'succeeded') {
          await this.paymentSucceeded()
        } else {
          // This should never happen since it should either be a success
          // or reject through to the error processing.
          captureSentryError(new Error('Unexpected card payment error 1'))
          this.displayPaymentError(this.$t('billing-markets.payment-error'))
        }
      } catch (error) {
        await this.processPaymentError(error)
      } finally {
        this.processingCardAndPaymentForm = false
      }
    },

    /**
     * User initiated method to make a one-off payment, supplying the credit card details.
     */
    async handleMakeOneOffPayment() {
      const valid = await this.validatePaymentForm()
      if (!valid) {
        return false
      }

      this.processingCardAndPaymentForm = true

      try {
        const paymentMethod = await this.createPaymentMethod()
        if (paymentMethod) {
          const oneOffPaymentPayload = {
            supplierId: this.marketObj?.id,
            amount: this.cardAndPaymentForm.amount,
            description: this.cardAndPaymentForm.description,
            paymentMethodId: paymentMethod.id,
          }
          const stripeInstance = sdk.instance.stripe()
          const { intentStatus } = await stripeInstance.makeOneOffPayment(oneOffPaymentPayload)
          if (intentStatus === 'succeeded') {
            await this.paymentSucceeded()
          } else {
            // This should never happen since it should either be a success
            // or reject through to the error processing.
            captureSentryError(new Error('Unexpected card payment error 2'))
            this.displayPaymentError(this.$t('billing-markets.payment-error'))

          }
        }
      } catch (error) {
        await this.processPaymentError(error)
      } finally {
        this.processingCardAndPaymentForm = false
      }
    },

    /**
     * Process when there was an error trying to make a payment either on a registered
     * card or a one-off payment.
     */
    async processPaymentError(error) {
      if (error.status === REQUIRE_AUTH_STATUS_CODE) {
        const { confirmed = false, requiresAuth = false, intentStatus } = error.data.data
        if (!confirmed && requiresAuth) {
          if (intentStatus === 'requires_payment_method') {
            const { clientSecret, paymentMethodId, resourceId } = error.data.data
            await this.confirmCardPayment(clientSecret, paymentMethodId, resourceId)
          } else {
            const { clientSecret, intentId, resourceId } = error.data.data
            await this.authoriseCardPayment(clientSecret, intentId, resourceId)
          }
        } else {
          // This should never happen - the above two scenarios should capture the possible states
          this.displayPaymentError(this.$t('billing-markets.payment-error'))
        }
      } else {
        this.displayPaymentError(error)
      }
    },

    /**
     * Handle payments that require confirmation for registered card payments.
     *
     * @param {string} clientSecret
     * @param {string} paymentMethodId
     * @param {integer} resourceId
     */
    async confirmCardPayment(clientSecret, paymentMethodId, resourceId) {
      try {
        const confirmationPayload = {
          payment_method: paymentMethodId,
        }
        const result = await this.cardAndPaymentForm.stripe.confirmCardPayment(clientSecret, confirmationPayload)
        if (result.error) {
          this.displayPaymentError(result.error.message)
          return false
        }

        // API confirmation
        const apiConfirmationPayload = {
          intentId: result.paymentIntent.id,
          supplierId: this.marketObj?.id,
          stripeConfirmed: true,
        }
        const stripeInstance = sdk.instance.stripe()
        const { confirmed, intentStatus } = await stripeInstance.paymentConfirmation(resourceId, apiConfirmationPayload)
        if (confirmed && intentStatus === 'succeeded') {
          await this.paymentSucceeded()
        } else {
          // This should never happen since it should either be a success
          // or reject through to the error processing.
          captureSentryError(new Error('Unexpected card payment error 3'))
          this.displayPaymentError(this.$t('billing-markets.payment-error'))
        }
      } catch (error) {
        this.displayPaymentError(error)
      }
    },

    /**
     * Handle payments that require authorisation for one-off payments.
     *
     * @param {string} clientSecret
     * @param {string} intentId
     * @param {integer} cardId
     */
    async authoriseCardPayment(clientSecret, intentId, cardId) {
      try {
        const result = await this.cardAndPaymentForm.stripe.handleCardAction(clientSecret)
        if (result.error) {
          this.displayPaymentError(result.error.message)
          return false
        }

        // Send it off to our API for it to confirm it with Stripe
        if (result.paymentIntent.status === 'requires_confirmation') {
          const apiConfirmationPayload = {
            intentId,
            supplierId: this.marketObj?.id,
          }
          const stripeInstance = sdk.instance.stripe()
          const { confirmed, intentStatus } = await stripeInstance.paymentConfirmation(cardId, apiConfirmationPayload)
          if (confirmed && intentStatus === 'succeeded') {
            await this.paymentSucceeded()
          } else {
            // This should never happen since it should either be a success
            // or reject through to the error processing.
            captureSentryError(new Error('Unexpected card payment error 4'))
            this.displayPaymentError(this.$t('billing-markets.payment-error'))
          }
        }
      } catch (error) {
        this.displayPaymentError(error)
      }
    },

    /**
     * Handle situation when a payment was successfully completed.
     */
    async paymentSucceeded() {
      const { currencyEnum, country } = this.marketObj.supply

      await this.$store.dispatch('Markets/syncSupplies')
      this.$store.commit('Notifications/notifyGood', {
        title: this.$t('billing-markets.payment-success'),
        message: this.$t('billing-markets.payment-success-message', {
          amount: this.processingCardAndPaymentForm.amount,
          currency: currencyEnum,
          market: country,
        }),
      })

      this.processingCardAndPaymentForm = false
      this.activePanel = null
      this.$emit('refreshPayments')
    },

    /**
     * User initiated method to update the card autopay setting
     */
    handleUpdateCard() {
      // No need for validations since it's just a switch
      this.updatingCard = true
      sdk.instance
        .stripe()
        .updateCard({
          autoPay: this.cardAndPaymentForm.autoPay,
          id: this.registeredCard.id,
          supplierId: this.marketObj && this.marketObj.id,
        })
        .then(res => {
          this.updatingCard = false
          this.$store.commit('Notifications/notifyGood', {
            title: this.$t('billing-markets.update-card-success'),
          })
          this.registeredCard.autoPay = res.card.autoPay
          this.activePanel = null
        })
        .catch(err => {
          this.updatingCard = false
          if (err.status === STRIPE_ERROR_STATUS_CODE) return this.displayStripeError(err.data)
          // TODO: Handle error processing better.
          const props = {
            title: this.$t('billing-markets.update-card-fail'),
            message: this.$t('billing-markets.update-card-fail-message', { error: err.data.message }),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)

          this.$store.commit('Notifications/notifyBad', {
            title: this.$t('billing-markets.update-card-fail'),
            message: err.data.message,
          })
        })
    },

    /**
     * User initiated method to remove a registered card.
     */
    handleRemoveCard() {
      this.removingCard = true
      sdk.instance
        .stripe()
        .deleteCard(this.registeredCard.id)
        .then(() => {
          this.removingCard = false
          this.registeredCard = {}

          this.$store.commit('Notifications/notifyGood', {
            title: this.$t('billing-markets.remove-card-success'),
          })
          this.activePanel = null
        })
        .catch(err => {
          this.removingCard = false
          if (err.status === STRIPE_ERROR_STATUS_CODE) return this.displayStripeError(err.data)
          // TODO: Handle error processing better
          const props = {
            title: this.$t('billing-markets.remove-card-fail'),
            message: this.$t('billing-markets.remove-card-fail', { error: err.data.message }),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          this.$store.commit('Notifications/notifyBad', {
            title: this.$t('billing-markets.remove-card-fail'),
            message: err.data.message,
          })
        })
    },

    /**
     * Display an error message when payment failed.
     *
     * @param {Object|string} error The error object with status and data.message, or a string
     */
    displayPaymentError(error) {
      if (error.status === STRIPE_ERROR_STATUS_CODE) {
        this.displayStripeError(error)
      } else {
        const message = this.$t('billing-markets.payment-attempt-failed', { error: error.data?.message || error })
        const badMessage = this.$t('billing-markets.payment-market-fail', { market: this.marketObj.country, error: error.data?.message || error })
        this.displayErrorAndNotifyBad(this.$t('billing-markets.payment-failed'), message, badMessage)
      }
    },

    /**
     * Display a more generic Stripe error message.
     *
     * @param {Object|string} error The error object with status and data.message, or a string
     */
    displayStripeError(error) {
      this.displayErrorAndNotifyBad(this.$t('billing-markets.stripe-error'), this.$t(error.data?.data?.message || error.data?.message || error))
    },

    /**
     * Worker method to process the error messages.
     *
     * @param {string} title The title for the notification and bad event
     * @param {string} message The message for the notification. Also used for bad event message unless overridden
     * @param {string} badMessage optional The message for the bad event. Overrides the ordinary message if supplied.
     */
    displayErrorAndNotifyBad(title, message, badMessage = null) {
      const props = {
        title,
        message,
        duration: 6000,
      }
      this.$notify.error(props)

      this.$store.commit('Notifications/notifyBad', {
        title,
        message: badMessage || message,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.button-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  button {
    margin: 0.5rem 0.5rem;
  }
}
.payment-form-container {
  max-width: 520px;
  margin: auto;
}
</style>

<style lang="scss">
.cc-input .el-form-item__error {
  bottom: 0.9em;
}
.cc-input .el-input__inner {
  padding-top: 1.1rem;
}
.cc-input.el-form-item {
  margin-bottom: 0;
}
</style>
