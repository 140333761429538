<template>
  <div v-if="isAccessDenied"
    class="main-content">
    <h4>{{ accessDenied }}</h4>
  </div>
  <div v-else
    class="main-content">
    <h2 data-testid="billing-markets-title"
      class="text-align-center">
      {{ $t('billing-markets.title') }}
    </h2>
    <template v-if="enabledMarkets && enabledMarkets.length > 0">
      <h3 class="text-align-center mt-2">
        {{ $t('billing-markets.enabled') }}
      </h3>

      <el-table :data="enabledMarkets"
        data-name="enabled-markets"
        :empty-text="$t('billing-markets.none')"
        stripe
        class="normal-wrap m-auto max-width-400px">
        <el-table-column property="country"
          :label="$t('connections.country')"
          label-class-name="capitalize-text">
          <template #default="{row}">
            <span :data-country="row.country">
              {{ row.country }}
            </span>
          </template>
        </el-table-column>

        <el-table-column :label="$t('billing-markets.options')"
          width="155"
          align="right"
          header-align="center">
          <template #default="{row}">
            <el-tooltip placement="top"
              :content="$t('billing-markets.view-invoices')"
              :open-delay="500">
              <el-button class="option-button"
                :data-option-invoice="row.country"
                @click="$router.push(`/company/markets/market/${row.id}/invoice`, () => { /* empty */ })">
                <i class="fal fa-file-alt"
                  aria-hidden="true" />
              </el-button>
            </el-tooltip>

            <el-tooltip v-if="!isDistributorAccount"
              placement="top"
              :content="$t('billing-markets.payment')"
              :open-delay="500">
              <el-button :data-option-payment="row.country"
                aria-label="Payments"
                class="option-button"
                @click="$router.push(`/company/markets/market/${row.id}/payments`, () => { /* empty */ })">
                <i class="fal fa-credit-card"
                  aria-hidden="true" />
              </el-button>
            </el-tooltip>

            <el-tooltip v-if="canViewBillingMarkets"
              placement="top"
              :content="$t('billing-markets.edit')"
              :open-delay="500">
              <el-button :data-option-edit="row.country"
                class="option-button"
                @click="$router.push(`/company/markets/market/${row.id}/edit`, () => { /* empty */ })">
                <i class="fal fa-edit"
                  aria-hidden="true" />
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <template v-if="canEditBillingMarkets">
      <h3 class="text-align-center mt-2">
        {{ $t('billing-markets.enable-bm') }}
      </h3>
      <el-table :data="emptyMarkets"
        :empty-text="$t('billing-markets.none-available')"
        stripe
        class="normal-wrap m-auto max-width-400px">
        <el-table-column property="country"
          :label="$t('connections.country')"
          label-class-name="capitalize-text" />
        <el-table-column align="right">
          <template #default="{row}">
            <el-button class="option-button fs-14px"
              type="success"
              :data-name="`enable-${row.country}`"
              @click="$router.push(`/company/markets/market/${row.id}/edit`, () => { /* empty */ })">
              {{ $t('billing-markets.enable') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <el-dialog :visible="currentOverlay ? true : false"
      data-name="edit-billing-market"
      :close-on-click-modal="false"
      :before-close="handleClose"
      :title="`${ $t('billing-markets.market')}: ${currentMarket.country}`">
      <company-market-tabs ref="companyMarketTabs"
        v-model="currentOverlay"
        :current-market="currentMarket"
        :current-overlay="currentOverlay"
        :can-edit-billing-markets="canEditBillingMarkets" />
      <template #footer>
        <el-button data-name="close-market-modal"
          @click="$router.push('/company/markets', () => { /* empty */ })">
          {{ $t('general.close') }}
        </el-button>
        <template v-if="currentOverlay === 'edit' && canEditBillingMarkets">
          <el-button @click="showMarketForm">
            {{ $t('general.revert') }}
          </el-button>
          <el-button data-name="save-company-markets"
            type="primary"
            @click="submitMarketForm">
            {{ $t('general.save') }}
          </el-button>
        </template>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import CompanyMarketTabs from '@/components/company/CompanyMarketTabs.vue'
import { DateTime } from 'luxon'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { mapGetters, mapState } from 'vuex'
import sdk from '@megaport/api-sdk'

export default {
  name: 'CompanyMarkets',

  components: {
    'company-market-tabs': CompanyMarketTabs,
  },

  inject: ['isFeatureEnabled'],

  data() {
    return {
      countryRules: null,
      currentOverlay: null,
      invoiceDownloading: false,
      cardPaymentsStore: null,
      registeredCardStore: null,
      showingMarketId: null,
    }
  },

  computed: {
    ...mapGetters('Auth', ['isDistributorAccount']),
    ...mapGetters('ApplicationContext', ['isManagedContext']),
    ...mapGetters('Auth', ['hasAuth']),
    ...mapGetters('Markets', ['markets']),
    ...mapState('Auth', { authData: state => state.data }),
    isAccessDenied() {
      return this.isFeatureEnabled('PAYS_BILL') && this.isManagedContext
    },
    accessDenied() {
      const pageName = this.$t(this.$route.meta.title)
      return this.$t('general.feature-flag-access-error', { pageName })
    },
    canViewBillingMarkets() {
      return this.hasAuth('financials') || this.hasAuth('company_admin')
    },
    canEditBillingMarkets() {
      return this.hasAuth('company_admin')
    },
    canDownloadInvoices() {
      return this.enabledMarkets.length > 0 && window.fetch
    },
    registeredCard() {
      if (this.registeredCardStore === null) {
        this.updateRegisteredCardStore()
      }
      return this.registeredCardStore
    },
    currentMarket() {
      const market = this.markets.find(m => m.id === parseInt(this.showingMarketId))
      if (!market)
        return {
          id: this.showingMarketId,
        }
      return market
    },
    enabledMarkets() {
      return this.markets.filter(m => (m.supply ? true : false))
    },
    emptyMarkets() {
      return this.markets.filter(m => (!m.supply ? true : false))
    },
  },

  watch: {
    '$route.params'() {
      this.setView()
    },
    showingMarketId() {
      this.registeredCardStore = null
      this.cardPaymentsStore = null
    },
  },

  created() {
    this.setView()
  },

  methods: {
    /**
     * Sets the UI view for the component based on route params
     */
    setView() {
      this.showingMarketId = this.$route.params.marketId || null
      this.currentOverlay = this.$route.params.action || null
      if (this.currentOverlay === 'edit') {
        this.showMarketForm()
      }
    },
    /**
     * Execute showMarketForm() in company market tabs component
     */
    showMarketForm() {
      if (!this.isManagedContext) {
        this.$nextTick(() => {
          this.$refs.companyMarketTabs?.showMarketForm()
        })
      }
    },
    /**
     * Execute submitMarketForm() in company market tabs component
     */
    submitMarketForm() {
      this.$nextTick(() => {
        this.$refs.companyMarketTabs.submitMarketForm()
      })
    },
    /**
     * Close modal window
     */
    handleClose() {
      this.$router.push('/company/markets', () => {
        // empty function is intentional
      })
    },
    downloadInvoices(format) {
      const formatEnum = {
        csv: 'text/csv',
        json: 'application/json',
      }

      if (!formatEnum[format]) return

      const headers = new Headers()

      headers.append('Accept', formatEnum[format])
      headers.append('Authorization', `Bearer ${this.authData.accessToken}`)
      headers.append('Content-Type', formatEnum[format])

      const req = {
        method: 'GET',
        headers: headers,
        mode: 'cors',
        cache: 'default',
      }
      this.invoiceDownloading = true
      fetch(`${sdk.instance.baseurl}/v2/invoice/csv`, req)
        .then(response => response.text())
        .then(data => {
          const filename = `megaport_invoices_${DateTime.now().toFormat('dd_LL_y')}.${format}`
          download(data, filename, formatEnum[format])
          this.invoiceDownloading = false
        })
        .catch(e => {
          captureSentryError(e)
        })
    },
    updateRegisteredCardStore() {
      this.registeredCardStore = {}
      sdk.instance
        .stripe()
        .getCards(this.currentMarket.id)
        .then(res => {
          this.registeredCardStore = res
        })
        .catch(e => {
          // 404 responses are expected when there are no cards registered.
          if (e.status !== 404) {
            captureSentryError(e)
          }
        })
    },
  },
}

const download = (data, filename, type) => {
  const a = document.createElement('a'),
    file = new Blob([data], {
      type: type,
    })
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename)
  else {
    // Others
    const url = URL.createObjectURL(file)
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    setTimeout(() => {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 0)
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 900px;
  min-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);

  .option-button {
    padding: 0.5rem 1rem;
    font-size: 1.7rem;
    margin-right: 0.5rem;
    margin-left: 0;
    &.fs-14px {
      font-size: 14px;
    }
  }
}

.max-width-400px {
  max-width: 400px;
}

::v-deep .el-dialog__body {
  padding: 0 20px;
}
</style>
